import axios from "axios";
import { getToken } from "./storage.utils";

const http = axios.create({
   baseURL: process.env.REACT_APP_LOCAL_BASE_URL, // use ths for production
  //baseURL: process.env.REACT_APP_LOCAL_BASE_URL_DEV,
  timeout: 10000,
});

http.interceptors.request.use(
  (config) => {
    if (getToken()) {
      config.headers["Authorization"] = `Bearer ${getToken()}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default http;
