import {
  RectangleGroupIcon,
  PencilSquareIcon,
  NewspaperIcon,
  UserGroupIcon,
  DocumentMagnifyingGlassIcon,
  ShieldCheckIcon,
  TableCellsIcon,
  DocumentTextIcon,
  DocumentCheckIcon,
  UserCircleIcon,
  UserPlusIcon,
  DocumentArrowUpIcon,
  Cog6ToothIcon,
  TruckIcon,
} from "@heroicons/react/24/outline";

const adminSidebarData = [
  [
    {
      id: "dashboard",
      name: "Dashboard",
      link: "/",
      hasSubMenus: false,
      icon: <RectangleGroupIcon className="w-6 h-6" />,
    },
    {
      id: "listBlogs",
      name: "Blogs",
      link: "/blog/list-blogs",
      hasSubMenus: true,
      icon: <NewspaperIcon className="w-6 h-6" />,
      subMenus: [
        {
          id: "listBlogs",
          name: "List",
          link: "/blog/list-blogs",
          icon: <DocumentTextIcon className="w-6 h-6" />,
        },
        {
          id: "addBlog",
          name: "Add Blog",
          link: "/blog/add",
          icon: <PencilSquareIcon className="w-6 h-6" />,
        },
      ],
    },
    {
      id: "adminUsers",
      name: "Admin",
      link: "/admin-users",
      hasSubMenus: true,
      icon: <ShieldCheckIcon className="w-6 h-6" />,
      subMenus: [
        {
          id: "adminUsers",
          name: "List",
          link: "/admin-users",
          icon: <UserCircleIcon className="w-6 h-6" />,
        },
        {
          id: "createAdmin",
          name: "Add",
          link: "/admin-users/add",
          icon: <UserPlusIcon className="w-6 h-6" />,
        },
      ],
    },
    {
      id: "staffsUsers",
      name: "Staffs",
      link: "/staffs-users",
      icon: <UserGroupIcon className="w-6 h-6" />,
      hasSubMenus: true,
      subMenus: [
        {
          id: "staffsUsers",
          name: "List",
          link: "/staffs-users",
          icon: <TableCellsIcon className="w-6 h-6" />,
        },
        {
          id: "createStaff",
          name: "Add",
          link: "/staff-user/add",
          icon: <UserPlusIcon className="w-6 h-6" />,
        },
      ],
    },
    {
      id: "reviewBlogs",
      name: "Review",
      link: "/review-blogs-requests",
      icon: <DocumentMagnifyingGlassIcon className="w-6 h-6" />,
      hasSubMenus: true,
      subMenus: [
        {
          id: "reviewBlogs",
          name: "Requests",
          link: "/review-blogs-requests",
          icon: <DocumentArrowUpIcon className="w-6 h-6" />,
        },
        {
          id: "approvedBlogs",
          name: "Approved",
          link: "/approved-blogs",
          icon: <DocumentCheckIcon className="w-6 h-6" />,
        },
      ],
    },
    {
      id: "rental",
      name: "Rental",
      link: "/rent-vehicle",
      icon: <TruckIcon className="w-6 h-6" />,
      hasSubMenus: true,
      subMenus: [
        {
          id: "rent-vehicle",
          name: "Rent Requests",
          link: "/rent-vehicle",
          icon: <DocumentArrowUpIcon className="w-6 h-6" />,
        },
        {
          id: "list-vehicle",
          name: "List Requests",
          link: "/list-vehicle",
          icon: <DocumentCheckIcon className="w-6 h-6" />,
        },
      ],
    },
  ],
  [
    {
      id: "settings",
      name: "Settings",
      link: "/settings",
      hasSubMenus: false,
      icon: <Cog6ToothIcon className="w-6 h-6" />,
    },
    // {
    //   id: "logout",
    //   name: "Logout",
    //   link: "/logout",
    //   hasSubMenus: false,
    //   icon: <ArrowLeftOnRectangleIcon className="w-6 h-6" />,
    // },
  ],
];

export default adminSidebarData;
