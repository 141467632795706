import { toast } from "react-hot-toast";
import http from "../utility/http.utils";

const getApiResponse = async ({
  url,
  method = "get",
  type = "application/json",
  data = {},
  otherParams = {},
}) => {
  try {
    const myHeader = {
      "Content-Type": type,
    };
    const result = http({
      method,
      url: `${url}`,
      data,
      headers: myHeader,
      params: {
        limit: "",
        ...otherParams,
      },
      accept: "*/*",
    });

    if (method !== "get") {
      toast.promise(result, {
        loading: "Loading...",
        success: "Success",
        error: "Error",
      });
    }

    return await result;
  } catch (error) {
    toast.error(
      error?.response?.data.error ||
        error?.response?.data?.message ||
        "Something went wrong, please try again later."
    );
    return {
      APIFailed: true,
      error: error.response,
    };
  }
};

export default getApiResponse;
