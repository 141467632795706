import React from "react";

const Input = ({
  type,
  placeholder = "",
  className,
  value,
  onChange,
  ...rest
}) => {
  return (
    <div>
      <input
        type={type}
        placeholder={placeholder}
        className={`border-2 border-gray-300 w-full outline-none  px-4 py-3 rounded-md mt-4 ${className}`}
        value={value}
        onChange={(e) => {
          onChange(e);
        }}
        {...rest}
      />
    </div>
  );
};

export default Input;
