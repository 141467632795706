import React, { Fragment, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";

import AppLayout from "./AppLayout";
import appRoutes from "./appRoutes";
import authRoutes from "./authRoutes";
import Login from "../auth/login/Login";
import PrivateLayout from "./PrivateLayout";

const mergedRoutes = [...authRoutes, ...appRoutes];

const MergeLayoutRoute = (props) => {
  const { children, route } = props;

  const AppLayoutWrapper = route.meta.appLayout ? AppLayout : Fragment;
  const PrivateRouteWrapper = route.meta.privateRoute
    ? PrivateLayout
    : Fragment;

  if (route.meta.privateRoute) {
    return <AppLayoutWrapper>{children}</AppLayoutWrapper>;
  } else {
    return <PrivateRouteWrapper>{children}</PrivateRouteWrapper>;
  }
};

const Router = () => {
  const loginStatus = useSelector((state) => state.user.loginStatus);

  return (
    <BrowserRouter>
      {!loginStatus ? (
        <Routes>
          <Route path="*" element={<Login />} />
        </Routes>
      ) : (
        <Routes>
          {mergedRoutes.map((route) => {
            return (
              <Route
                key={route.id}
                path={route.path}
                exact={route.exact}
                element={
                  <Suspense
                    fallback={
                      <div className="flex w-full h-[100vh] items-center justify-center">
                        Buzzing...
                      </div>
                    }
                  >
                    <MergeLayoutRoute route={route}>
                      <route.component />
                    </MergeLayoutRoute>
                  </Suspense>
                }
              />
            );
          })}
        </Routes>
      )}
    </BrowserRouter>
  );
};

export default Router;
