import NavBar from "components/navBar/NavBar";
import Sidebar from "components/sidebar/Sidebar";

const AppLayout = ({ children }) => {
  return (
    <>
      <NavBar />
      <div className="flex">
        <Sidebar />
        <div className="w-full px-8 py-12 ml-[4.5rem] overflow-x-hidden">
          {children}
        </div>
      </div>
    </>
  );
};

export default AppLayout;
