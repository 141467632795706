import { lazy } from "react";

const Dashboard = lazy(() => import("pages/dashboard/Dashboard"));
const AdminUsers = lazy(() => import("pages/adminUsers/AdminUsers"));
const StaffsUsers = lazy(() => import("pages/staffsUsers/StaffsUsers"));
const Blogs = lazy(() => import("pages/blogs/listBlog/Blogs"));
const EditBlog = lazy(() => import("pages/blogs/editBlog/EditBlog"));
const AddBlog = lazy(() => import("pages/blogs/addBlog/AddBlog"));
const BlogDetails = lazy(() => import("pages/blogs/blogDetails/BlogDetails"));
const Settings = lazy(() => import("pages/settings/Settings"));
const RequestForApprovalBlogs = lazy(() =>
  import("pages/OnReviewBlogs/RequestForApproval/RequestForApproval")
);
const ApprovedBlogs = lazy(() =>
  import("pages/OnReviewBlogs/ApprovedBlogs/ApprovedBlogs")
);

const BlogCategories = lazy(() =>
  import("pages/blogs/blogCategories/BlogCategories")
);

const ImageGallery = lazy(() => import("pages/imageGallery/ImageGallery"));
const BlogComments = lazy(() => import("pages/blogs/comments/BlogComments"));
const Newsletter = lazy(() => import("pages/newsLetter/NewsLetter"));

const TravelPhotos = lazy(() =>
  import("pages/travelPhotos/listTravelPhotos/TravelPhotos")
);
const AddTravelPhoto = lazy(() =>
  import("pages/travelPhotos/addTravelPhoto/AddTravelPhoto")
);
const EditTravelPhoto = lazy(() =>
  import("pages/travelPhotos/editTravelPhoto/EditTravelPhoto")
);
const TravelPhotosDetails = lazy(() =>
  import("pages/travelPhotos/travelPhotosDetail/TravelPhotosDetail")
);

const TravelVideos = lazy(() =>
  import("pages/travelVideo/listTravelVideo/TravelVideo")
);
const AddTravelVideo = lazy(() =>
  import("pages/travelVideo/addTravelVideo/AddTravelVideo")
);
const EditTravelVideo = lazy(() =>
  import("pages/travelVideo/editTravelVideo/EditTravelVideo")
);
const TravelVideosDetails = lazy(() =>
  import("pages/travelVideo/travelVideoDetail/TravelVideoDetail")
);

// Service package

const ServicePackage = lazy(() =>
  import("pages/servicePackage/servicePackage/ServicePackage")
);
const CrateServicePackage = lazy(() =>
  import("pages/servicePackage/createServicePackage/CreateServicePackage")
);

const EditServicePackage = lazy(() =>
  import("pages/servicePackage/editServicePackage/EditServicePackage")
);

const InquirePackage = lazy(() =>
  import("pages/servicePackage/inquiryPackage")
);

const InquirePackageDetails = lazy(() =>
  import("pages/servicePackage/inquiryPackage/InquiryPackageDetails")
);
const NotFoundPage = lazy(() => import("pages/404/NotFound"));

const RentVehicle = lazy(() => import("pages/rentVehicle/RentVehicle"));

const RentVehicleDetails = lazy(() =>
  import("pages/rentVehicle/rentVehicleDetails/index")
);

const ListVehicle = lazy(() => import("pages/listVehicle/ListVehicle"));
const ListVehicleDetails = lazy(() =>
  import("pages/listVehicle/listVehicleDetails/index")
);

const PackageCategory = lazy(() =>
  import("pages/packageCategory/PackageCategory")
);

const appRoutes = [
  {
    id: "dashboard",
    path: "/",
    exact: true,
    component: Dashboard,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "listBlogs",
    path: "/blog/list-blogs",
    exact: true,
    component: Blogs,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "editBlogs",
    path: "/blog/edit/:blogId",
    exact: true,
    component: EditBlog,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "addBlogs",
    path: "/blog/add",
    exact: true,
    component: AddBlog,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "comments-list",
    path: "/blog/comments",
    exact: true,
    component: BlogComments,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "blog-categories",
    path: "/blog/category",
    exact: true,
    component: BlogCategories,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "blogDetails",
    path: "/blog/details/:blogId",
    exact: true,
    component: BlogDetails,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "listTravelPhotos",
    path: "/travel-photos/list",
    exact: true,
    component: TravelPhotos,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "addTravelPhotos",
    path: "/travel-photos/add",
    exact: true,
    component: AddTravelPhoto,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "editTravelPhotos",
    path: "/travel-photos/edit/:travelPhotoId",
    exact: true,
    component: EditTravelPhoto,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "travelPhotosDetails",
    path: "/travel-photos/details/:travelPhotoId",
    exact: true,
    component: TravelPhotosDetails,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "listTravelVideos",
    path: "/travel-videos/list",
    exact: true,
    component: TravelVideos,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "addTravelVideos",
    path: "/travel-videos/add",
    exact: true,
    component: AddTravelVideo,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "editTravelVideos",
    path: "/travel-videos/edit/:travelVideoId",
    exact: true,
    component: EditTravelVideo,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "travelVideoDetails",
    path: "/travel-videos/details/:travelVideoId",
    exact: true,
    component: TravelVideosDetails,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "reviewBlogs",
    path: "/review-blogs-requests",
    exact: true,
    component: RequestForApprovalBlogs,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "approvedBlogs",
    path: "/approved-blogs",
    exact: true,
    component: ApprovedBlogs,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "image-gallery",
    path: "/image-gallery",
    exact: true,
    component: ImageGallery,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "news-letter",
    path: "/news-letter",
    exact: true,
    component: Newsletter,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "staffsUsers",
    path: "/staffs-users",
    exact: true,
    component: StaffsUsers,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "404",
    path: "*",
    exact: true,
    component: NotFoundPage,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  // {
  //   id: "addStaffs",
  //   path: "/staffs/add-staff",
  //   exact: true,
  //   component: AddStaffs,
  //   meta: {
  //     appLayout: true,
  //     privateRoute: true,
  //   },
  // },
  // {
  //   id: "editStaff",
  //   path: "/staffs/edit-staff/:id",
  //   exact: true,
  //   component: EditStaff,
  //   meta: {
  //     appLayout: true,
  //     privateRoute: true,
  //   },
  // },
  {
    id: "adminUsers",
    path: "/admin-users",
    exact: true,
    component: AdminUsers,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "settings",
    path: "/settings",
    exact: true,
    component: Settings,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },

  {
    id: "servicePackage",
    path: `/service-package`,
    exact: true,
    component: ServicePackage,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },

  {
    id: "servicePackage",
    path: `/create-service-package`,
    exact: true,
    component: CrateServicePackage,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },

  {
    id: "editServicePackage",
    path: `/edit-service-package/:id`,
    exact: true,
    component: EditServicePackage,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },

  {
    id: "packageInquiry",
    path: `/package-inquiry`,
    exact: true,
    component: InquirePackage,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },

  {
    id: "packageInquiryDetails",
    path: `/package-inquiry/:id`,
    exact: true,
    component: InquirePackageDetails,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "rentVehicle",
    path: `/rent-vehicle`,
    exact: true,
    component: RentVehicle,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "rentVehicleDetails",
    path: `/rent-vehicle/details/:id`,
    exact: true,
    component: RentVehicleDetails,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "listVehicle",
    path: `/list-vehicle`,
    exact: true,
    component: ListVehicle,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "listVehicleDetails",
    path: `/list-vehicle/details/:id`,
    exact: true,
    component: ListVehicleDetails,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "packageCategory",
    path: `/package-category`,
    exact: true,
    component: PackageCategory,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
];

export default appRoutes;
