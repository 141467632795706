import { useState } from "react";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

import Input from "components/input/Input";
import loginImage from "assets/illustration/loginPkrBuzz.png";
import userLoginApi from "services/auth/loginApi";
import { useDispatch } from "react-redux";
import { setLogin } from "redux/slices/userSlice";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const dispatch = useDispatch();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isRememberMe, setIsRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const response = await userLoginApi({ email: userName, password });

    if (response) {
      dispatch(
        setLogin({
          userData: response?.data.userData,
          token: response?.data.token,
          isRememberMe,
        })
      );
      navigate("/");
    }
  };

  return (
    <div className="flex h-[100vh] items-center justify-center gap-10 p-20">
      <div>
        <h1 className="text-7xl text-darkAsscent font-bold">Everest Buzz</h1>
        <div className="flex items-center mt-4 space-x-4">
          <div>
            <h2 className="mb-5 text-3xl text-gray-500">
              Sign in to admin site
            </h2>
          </div>
        </div>

        <form>
          <div>
            <label className="inputLabel">Username</label>
            <Input
              type="text"
              className="w-96 focus:border-1 focus:ring-1 focus:ring-darkAsscent"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              autoComplete="username"
            />
          </div>

          <div className="mt-5">
            <label className="inputLabel">Password</label>
            <div className="w-96 relative flex items-center justify-between mt-4 space-x-3 border-2 border-gray-300 rounded-md outline-none">
              <div className="w-full">
                <Input
                  type={showPassword ? "text" : "password"}
                  className="flex-1 w-full px-4 mt-0 border-0"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <div
                onClick={() => setShowPassword(!showPassword)}
                className="right-5 absolute cursor-pointer"
              >
                {showPassword ? (
                  <EyeIcon className="h-5" />
                ) : (
                  <EyeSlashIcon className="h-5" />
                )}
              </div>
            </div>
          </div>
          <div className="flex items-center mt-3 space-x-2">
            <input
              type="checkbox"
              value={isRememberMe}
              onChange={() => setIsRememberMe((prev) => !prev)}
            />
            <p>Remember Me</p>
          </div>

          <div className="mt-4">
            <button
              type="submit"
              onClick={handleFormSubmit}
              className="primaryButton bg-darkAsscent"
            >
              Login
            </button>
          </div>
        </form>
      </div>

      <div className="w-96 h-auto">
        <img src={loginImage} alt="Login Illustration" />
      </div>
    </div>
  );
};

export default Login;
