import {
  RectangleGroupIcon,
  ChartBarIcon,
  PhotoIcon,
  PlusCircleIcon,
  TableCellsIcon,
} from "@heroicons/react/24/outline";

const clientSideBarData = [
  [
    {
      id: "dashboard",
      name: "Dashboard",
      link: "/",
      hasSubMenus: false,
      icon: <RectangleGroupIcon className="w-6 h-6" />,
    },

    {
      id: "travelPhotos",
      name: "Travel Photos",
      link: "/travel-photos/list",
      hasSubMenus: true,
      icon: <PhotoIcon className="w-6 h-6" />,
      subMenus: [
        {
          id: "listTravelPhotos",
          name: "List",
          link: "/travel-photos/list",
          icon: <TableCellsIcon className="w-6 h-6" />,
        },
        {
          id: "addTravelPhoto",
          name: "Add Travel Photo",
          link: "/travel-photos/add",
          icon: <PlusCircleIcon className="w-6 h-6" />,
        },
      ],
    },

    {
      id: "servicePackage",
      name: "Service Package",
      icon: <ChartBarIcon className="w-6 h-6" />,
      hasSubMenus: true,
      subMenus: [
        {
          id: "package-category",
          name: "Category",
          link: "/package-category",
        },
        {
          id: "packages",
          name: "Packages",
          link: "/service-package",
        },
        {
          id: "packageInquiry",
          name: "Package Inquiry",
          link: "/package-inquiry",
        },
      ],
    },
  ],
];

export default clientSideBarData;
